import React from 'react'
import Typography from '~/components/atoms/Typography'
import CMSContent from '~/components/molecules/CMSContent'
import ServiceList from '~/components/organisms/ServiceList'
import NewsList from '~/components/organisms/NewsList'
import { container, article, page_title, bg_primary } from './styles'
import { Container } from '~/components/layout/Container'

type Props = {
	data: GatsbyTypes.OrdersQuery
}

const Orders: React.VFC<Props> = (props) => {
	return (
		<>
			<Container css={container}>
				<article css={article}>
					<Typography variant='h5' color='textSecondary'>特定商取引法に基づく表記</Typography>
					<Typography component='h1' variant='h2' color='textPrimary' css={page_title}>特定商取引法に基づく表記をご確認ください。</Typography>
				</article>

				<CMSContent
					css={article}
					html={props.data.microcmsOrders.content}
				/>
			</Container>

			<div css={bg_primary}>
				<ServiceList />
			</div>

			<NewsList />
		</>
	)
}

export default Orders
