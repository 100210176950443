import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Orders from '~/components/pages/Orders'
import SEO from '~/utils/seo'

export default function OrdersPage({ data, location }: PageProps<GatsbyTypes.OrdersQuery>) {
    return (
        <>
            <SEO
                title='特定商取引法に基づく表記 | Onsen*(オンセン)'
                description='Onsen*Storeの特定商取引法に基づく表記をご確認ください。'
                robots='all'
                url={location.href}
            />
            <Orders data={data} />
        </>
    )
}

export const query = graphql`
	query Orders {
		 microcmsOrders {
		    content
		 }
	}
`
